export const defaultLocale = 'de-DE'

export enum ArticleDetailsElementsById {
  StickyBox = 'article-sticky-box',
}

export enum ArticleDetailsSections {
  Description = 'article-description',
  TechnicalSpecification = 'article-technical-specification',
  Accessories = 'article-accessories',
  Recommendations = 'article-recommendations',
  Alternative = 'article-alternative',
  Downloads = 'article-downloads',
  Category = 'article-category',
  StockAssortmentCatalog = 'article-stock-assortment-catalog',
  QuestionsAndAnswers = 'article-questions-and-answers',
  CustomerReviews = 'article-customer-review',
  CrossSelling = 'article-crossselling',
  UpSelling = 'article-upselling',
  OxomiCatalog = 'oxomi-catalog',
}

export enum ShopRoutes {
  ArticleDetailPage = '/article-detail',
  Interfaces = '/interfaces', // Schnittstellen
  Search = '/search',
  Root = '/',
  Orders = '/orders',
  Projects = '/projects',
  Offers = '/offers',
  OfferList = '/offer-list',
  OfferDetails = '/offer-details',
  OrdersList = '/orders-list',
  OrderDetails = '/order-details',
  OrderItemsInArrears = '/order-items-in-arrears',
  Templates = '/onlineshop/templates',
  TemplateList = '/cart-template-list',
  TemplateDetails = '/cart-template-details',
  RentArticlesNew = '/rent-articles', //Mietgeräte v2
  QuickRegister = '/quick-register', //Schnellerfassung Legacy
  FastProductEntry = '/fast-article-entry', //Schnellerfassung
  Register = '/register', //neuerBenutzer
  ActivateAccount = '/activate', //neuerBenutzer
  ActivateShopAccess = '/shop-activation', // Freischaltung online Zugang
  Upload = '/upload',
  Datanorm = '/datanorm', //Schnittstellen
  Elbridge = '/elbridge',
  ConfiguratorPage = '/configurator-page',
  DOP = '/baupvo', //Leistungserklärung
  Settings = '/settings', //Einstellungen
  UserAdministration = '/user', //Benutzerverwaltung
  OpenInvoiceItems = '/open-invoice-items', //deprecated route - replaced by MyAccounting (/my-accounting) - Offene Posten
  Account = '/account', // //deprecated route - replaced by MyAccounting (/my-accounting) - Kontoinformation
  MyAccounting = '/my-accounting', // Kontoinformation
  News = '/news', // Nachrichten
  Advertising = '/advertising',
  Events = '/events', //veranstaltungen
  InfoDays = '/info-days', //informationstage
  ShoppingCartList = '/shopping-cart-list',
  ShoppingCartDetails = '/shopping-cart-details',
  ShoppingCartCheckout = '/shopping-cart-checkout',
  Stores = '/stores', // standorte
  AboutUs = '/about-us', // impressum
  PrivacyProtection = '/privacy-protection', //datenschutz
  Terms = '/terms-and-conditions', //agb
  Services = '/services', //Leistungen
  Suppliers = '/suppliers', //Lieferanten
  Contact = '/contact', //Kontakt
  Promotion = '/promotion',
  NewsDetailPage = '/news-detail',
  TeaserDetailPage = '/teaser-detail',
  CustomDatasheetPage = '/onlineshop/custom-datasheet-page',
  Redirect = '/redirect',
  Tecworld = '/tecworld',
  ApiRouteVersion = '/api/version',
  ApiRouteSendMail = '/api/sendmail',
  ApiRouteMaintenance = '/api/maintenance',
  SessionCatalogStartPage = '/session-catalog-start',
  StaticContent = '/content',
  CreateOrderPage = '/create-order',
  SuppliersOverviewPage = '/suppliers-overview',
  PdfOverview = '/pdf-overview',
  Carts = '/carts',
  UserOverview = '/user-overview', // Neue Benutzerübersicht
  UserManagement = '/user-management', // Neue Benutzerverwaltung
  OxomiStory = '/story',
  OciBackgroundSearchResults = '/oci-background-search-results',
  LegacyIdsRoute = '/ids/login',
  LegacyIdsFilenameRoute = '/IDS-Interface.html',
  UserEmailConfirmation = '/user-email-confirmation',
  SignUp = '/sign-up', // Neukundenregistrierung
  ProjectOverview = '/project-overview',
}

export enum LoginRoutes {
  Login = '/login',
  Logout = '/logout',
  Consent = '/consent',
  SupportLogin = '/support/login',
  SupportConsent = '/support/consent',
}

// TODO: currently we assume that "main" stock has warehouseId that equals to '10'
// Stock interface stored here: libs\models\src\lib\models\Article\Shop\Product.ts
export const MainStockId = '10'

export enum PageIds {
  Terms = 'terms',
  About = 'aboutUs',
  Privacy = 'privacyProtection',
}

export const contentRoutes = {
  userManagementInfoPage: `${ShopRoutes.StaticContent}/info-benutzerverwaltung`,
  ourStrengthInfoPage: `${ShopRoutes.StaticContent}/UnsereStaerken`,
} as const
