import { catchError, concatMap, defer, mergeMap, Observable, of, retry, switchMap } from 'rxjs'
import {
  CustomerActionTypes,
  UpdateCardAction,
  updateCardResult,
  LoadUserGraphQLAction,
  loadUserGraphQLResult,
  UpdateUserSettingsAction,
  DeleteUserToken,
} from '../actions/customer-actions'

import { deleteFirebasePushToken } from '@obeta/models/lib/models/Tokens/Tokens'
import { getUserV2Result, UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { ofType } from 'redux-observable'
import { noop } from '../actions'
import { RxDatabase, CollectionsOfDatabase } from 'rxdb'
import { EventType, NotificationType, getEventSubscription } from '@obeta/utils/lib/pubSub'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { gqlQueryUserV2, gqlUpdateUserV2, assignCustomerCard } from '../queries/userV2'

export interface CustomerSettings {
  creditLimitWarning: boolean
  orderConfirmation: boolean
  defaultProjectId: string
}

const createUpdateCardEpic = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  const updateCard = async (cardId: string) => {
    const response = await apolloClient.mutate({
      mutation: assignCustomerCard,
      variables: {
        input: {
          barcode: cardId,
        },
      },
    })

    if (response.data.assignCustomerCard.type === 'Error') {
      return updateCardResult({
        ...response.data.assignCustomerCard,
        message: response.data.assignCustomerCard.message,
      })
    }

    const user = await db.getLocal<UserV2>('userv2')
    await user?.incrementalModify((user) => {
      return { ...user, customerCard: { ...user.customerCard, barcode: cardId } }
    })

    getEventSubscription().next({
      type: EventType.Toast,
      notificationType: NotificationType.Toast,
      id: 'user card toast',
      options: {
        message: 'Die Kundenkarte wurde erfolgreich hinzugefügt',
      },
    })

    return updateCardResult()
  }

  return (actions$: Observable<UpdateCardAction>) =>
    actions$.pipe(
      ofType(CustomerActionTypes.UpdateCard),
      switchMap(async (action: UpdateCardAction) => {
        return await updateCard(action.cardId)
      }),
      catchError((error) => {
        handleError({
          ...error,
          message: 'error in ' + createUpdateCardEpic.name + ' ' + error.message,
        })
        return of(updateCardResult(error))
      })
    )
}

const createUpdateUserV2 = <Action>(
  requestUser: (action: Action) => Promise<UserV2>,
  db: RxDatabase<CollectionsOfDatabase>
) => {
  return (action: Action) => {
    return defer(async () => {
      const user = await requestUser(action)
      const userBase = await db.getLocal<UserV2>('userv2')
      await userBase?.incrementalModify((data) => ({ ...data, ...user }))
      return user
    }).pipe(
      retry(1),
      mergeMap((result: UserV2) => of(loadUserGraphQLResult(result))),
      catchError((error) => {
        error.message = 'error in ' + createUpdateUserV2.name + ' ' + error.message
        handleError(error)
        return of(loadUserGraphQLResult(undefined, error))
      })
    )
  }
}

export const createLoadUserGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  const updateUserV2 = createUpdateUserV2<LoadUserGraphQLAction>(async () => {
    const response = await apolloClient.query<getUserV2Result>({
      query: gqlQueryUserV2,
    })

    return response.data.getUser
  }, db)
  return (actions$: Observable<LoadUserGraphQLAction>) =>
    actions$.pipe(
      ofType(CustomerActionTypes.LoadUserGraphql),
      concatMap((action: LoadUserGraphQLAction) => updateUserV2(action))
    )
}

export const createUpdateUserSettings = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  const updateUserV2 = createUpdateUserV2<UpdateUserSettingsAction>(async (action) => {
    const response = await apolloClient.mutate<{ updateUserSettings: UserV2 }>({
      mutation: gqlUpdateUserV2,
      variables: {
        input: action.payload,
      },
    })
    const user = response.data?.updateUserSettings
    if (!user) {
      throw new Error("updateUserSettings: response doesn't have new user")
    }

    return user
  }, db)

  return (actions$: Observable<UpdateUserSettingsAction>) => {
    return actions$.pipe(
      ofType(CustomerActionTypes.UpdateUserSettings),
      switchMap((action) => updateUserV2(action))
    )
  }
}

const createDeleteUserTokenEpic = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  const deleteToken = async () => {
    const pushToken = await db.getLocal('pushToken')
    const token = pushToken?.get('token')

    if (!token) {
      return
    }

    try {
      const response = await apolloClient.mutate({
        mutation: deleteFirebasePushToken,
        variables: {
          token,
        },
      })
      return { deleteToken: response.data.deleteToken }
    } catch (err) {
      err.message = 'error in ' + deleteToken.name + ' ' + err.message
      handleError(err)
      throw new Error(err)
    }
  }

  return (actions$: Observable<DeleteUserToken>) => {
    return actions$.pipe(
      ofType(CustomerActionTypes.DeleteUserToken),
      switchMap(async () => {
        await deleteToken()

        return noop()
      })
    )
  }
}

export const initAllCustomerEpics = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return [
    createUpdateCardEpic(db, apolloClient),
    createLoadUserGraphQLEffect(db, apolloClient),
    createUpdateUserSettings(db, apolloClient),
    createDeleteUserTokenEpic(db, apolloClient),
  ]
}
