/**
 * Structure of one article for an ArticleList
 */
import { ArticleDetails, Oxomi } from './ArticleDetails'

export enum ArticleRing {
  Lagerartikel = 'Lagerartikel',
  Sonderbestellung = 'Sonderbestellung',
  Divers = 'Divers',
  Null = '',
}

export interface ArticleListItem {
  id: string
  description: string
  prices: Prices
  stock: ArticleStock
  eeValue: string
  imagePaths: ImagePaths
  minimumAmount: number
  ring: ArticleRing
  statusText: string
  details?: ArticleDetails
  oxomi?: Oxomi
  isOrderable?: boolean
  currentlyNotAvailable: boolean
  shoppingCart?: {
    amount: number
    price: number
  }
}

export interface ArticleAddOptions {
  articleId: string
  articleName: string
  articleRing: ArticleRing
  shoppingCartId: string
  shoppingCartName: string
  addedAmount: number
  currentAmountInCart?: number
}

export interface Prices {
  overwritten?: {
    price: string
    unit: string
  }
  new: {
    price: string
    unit?: string
  }
}

export interface ArticleStock {
  stockAmount?: string
  unit: string
  status: string
}

export interface ImagePaths {
  path: string
  logoPath: string
}
