import React, { ReactNode } from 'react'
import { Box } from '@mui/material'
import clsx from 'clsx'
import {
  isPageMatch,
  isStartPage,
  PAGES_FULL_WIDTH,
  PAGES_SIGNUP,
} from '@obeta/utils/lib/styles-helpers'
import styles from './PageMarginWrapper.module.scss'

const getPageStyles = (pageRoute: string): string => {
  let pageStyles = styles.page
  if (isStartPage(pageRoute)) {
    pageStyles = styles.pageStart
  } else if (isPageMatch(pageRoute || '', PAGES_FULL_WIDTH)) {
    pageStyles = styles.pageWithFullWidth
  } else if (isPageMatch(pageRoute || '', PAGES_SIGNUP)) {
    pageStyles = styles.pageUserRegistration
  }
  return pageStyles
}

export interface IPageMarginWrapperProps {
  children: ReactNode
  pageRoute: string
  className?: string
}

export const PageMarginWrapper: React.FC<IPageMarginWrapperProps> = (props) => {
  const { pageRoute, className, children } = props

  return <Box className={clsx(getPageStyles(pageRoute), className)}>{children}</Box>
}
