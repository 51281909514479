export const removeTypename = <T>(obj: T): T => {
  if (Array.isArray(obj)) {
    return obj.map(removeTypename) as unknown as T
  } else if (obj && typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([key]) => key !== '__typename')
        .map(([key, value]) => [key, removeTypename(value)])
    ) as T
  }
  return obj
}
