import type { Location, Path } from 'history-types'
import React, { createContext, useContext, useMemo } from 'react'
import { validateContextValue } from '@obeta/utils/lib/validateContextValue'
import {
  // here the imports are ok, as they do provide the wrappers we then should use
  // eslint-disable-next-line no-restricted-imports
  useNavigate,
  // eslint-disable-next-line no-restricted-imports
  useLocation as useReactRouterLocation,
  // eslint-disable-next-line no-restricted-imports
  useParams as useReactRouterParams,
} from 'react-router'
import { useRouter } from 'next/router'
import { decompressFromEncodedURIComponent } from 'lz-string'
import { buildNextRouterState } from '@obeta/utils/lib/history/buildNextRouterState'

// Models
import { Params, RouterApi } from '@obeta/models/lib/models/History'

const routerParamRegEx = /\?.+/

const RouterApiContext = createContext<RouterApi | null>(null)
export const RouterApiProvider: React.FC<{ api: RouterApi }> = (props) => {
  return <RouterApiContext.Provider value={props.api}>{props.children}</RouterApiContext.Provider>
}

/**
 * I don't store params inside router api object.
 * in react-router-dom params stored in seperate context (Route context)
 * To make this work we also must store params in different context
 */

const RouterParamsContext = createContext<null | (() => Params)>(null)
export const RouterParamsProvider: React.FC<{ params: () => Readonly<Params> }> = (props) => {
  return (
    <RouterParamsContext.Provider value={props.params}>
      {props.children}
    </RouterParamsContext.Provider>
  )
}

const useRouterApi = () => {
  return validateContextValue(useContext(RouterApiContext), 'RouterApiProvider', 'useRouterApi')
}

export const useHistory = () => {
  const { history } = useRouterApi()

  return history
}

export const useLocation = () => {
  const { location } = useRouterApi()
  return location
}

export const useParams = <
  Params extends { [K in keyof Params]?: string } = Record<string, string>
>(): Params => {
  const routeParams = validateContextValue(
    useContext(RouterParamsContext),
    'RouterParamsProvider',
    'useParams'
  )
  return routeParams() as Params
}

/**
 * api provider
 * Use this provider if you use react-router-dom.
 */

export const ReactRouterProvider: React.FC = (props) => {
  const nav = useNavigate()
  const location = useReactRouterLocation()

  const api = useMemo(
    () => ({
      push: (path: string, state: Record<string, unknown>) => {
        nav(path, { state })
      },
      replace: (path: string, state: Record<string, unknown>) => {
        nav(path, { replace: true, state })
      },
      goBack: () => {
        nav(-1)
      },
      go: (to: number) => {
        nav(to)
      },
      goForward: () => {
        nav(1)
      },
      length: typeof window === 'undefined' ? 0 : window.history.length, // this code runs in SSR aswell
      location,
    }),
    [location, nav]
  )

  return <RouterApiProvider api={{ history: api, location }}>{props.children}</RouterApiProvider>
}

/**
 * params provider
 * Use this provider if you use react-router-dom.
 */

export const ReactRouterParamsProvider: React.FC = (props) => {
  const params = useReactRouterParams as () => Params
  return <RouterParamsProvider params={params}>{props.children}</RouterParamsProvider>
}

/**
 * api and params provider
 * Use this provider if you use next-router
 */

/**
 * @param {Array} routes Array of objects that map url pages to their file names
 */

export const NextJSRouterProvider: React.FC = (props) => {
  const nextJsRouter = useRouter()
  const { api, routeParams } = useMemo(() => {
    let state: Record<string, string> | undefined = undefined
    if (typeof nextJsRouter.query.state === 'string') {
      try {
        state = JSON.parse(decompressFromEncodedURIComponent(nextJsRouter.query.state) as string)
      } catch (err) {
        state = undefined
      }
    }

    const routeSource = nextJsRouter.asPath

    const pathname = routeSource.replace(routerParamRegEx, '')
    const location: RouterApi['location'] = {
      pathname,
      state,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      search: new URLSearchParams(nextJsRouter.query as any).toString(),
    }

    const historyApi: RouterApi['history'] = {
      push: (path: Path | Location, state, options = {}) => {
        const p = buildNextRouterState(path, state)
        nextJsRouter.push(p, undefined, { scroll: true, shallow: true, ...options })
      },
      goBack() {
        nextJsRouter.back()
      },
      goForward() {
        global.history.go(1)
      },
      location,
      length: global.history.length,
      replace(path: Path | Location, state?: unknown, options = {}) {
        const p = buildNextRouterState(path, state)
        nextJsRouter.replace(p, undefined, { scroll: false, shallow: true, ...options })
      },
      go(n) {
        global.history.go(n)
      },
    }

    return {
      api: { history: historyApi, location },
      routeParams: () => nextJsRouter.query as Params,
    }
  }, [nextJsRouter])

  return (
    <RouterApiProvider api={api}>
      <RouterParamsProvider params={routeParams}>{props.children}</RouterParamsProvider>
    </RouterApiProvider>
  )
}
