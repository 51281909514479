import { FC } from 'react'
import styles from './SignUpFooter.module.scss'
import { Divider } from '@mui/material'
import { StrengthSection } from '../startPage/StrengthSection'
import { Section } from '../startPage/Section'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { strengths } from './constants/strengths'
import { ShopLink } from '@obeta/components/lib/link/ShopLink'

const linksConfig = [
  { href: '/terms-and-conditions', linkText: 'AGB' },
  { href: '/privacy-protection', linkText: 'Datenschutz' },
  { href: '/about-us', linkText: 'Impressum' },
]

export const SignUpFooter: FC = () => {
  const { mobile } = useBreakpoints()
  const strengthsTitlePosition = mobile ? 'start' : 'center'

  return (
    <div className={styles['sign-up-footer-wrapper']}>
      {!mobile && (
        <div className={styles['strength-card']}>
          <Section
            content={<StrengthSection strengths={strengths} compact />}
            titlePosition={strengthsTitlePosition}
          />
        </div>
      )}
      <Divider />
      <div className={styles['footer-content']}>
        {linksConfig.map(({ href, linkText }) => (
          <ShopLink
            key={href}
            href={href}
            target="_blank"
            rel="noreferrer"
            underline="none"
            color="inherit"
          >
            {linkText}
          </ShopLink>
        ))}
      </div>
    </div>
  )
}

export default SignUpFooter
