import { EntityDescriptor } from '@obeta/models/lib/models/Db/index'
import { ShoppingCart } from '@obeta/schema'
import { GET_SHOPPING_CARTS } from '../queries/getShoppingCarts'
import { print } from 'graphql/language/printer'

interface ICheckpoint {
  since?: string
  lastId?: string
}

const emptyCheckpoint: ICheckpoint = {
  since: undefined,
  lastId: undefined,
}

const createCheckpoint = (doc: ShoppingCart): ICheckpoint => ({
  lastId: doc.id,
  since: doc.updatedAt,
})

export const cartsv2Entity: EntityDescriptor<
  Omit<ShoppingCart, 'deleted' | '__typename' | 'shoppingCartPrices'> & { isUpdating?: boolean },
  ICheckpoint
> = {
  name: 'cartsv2',
  migrationStrategies: {
    1: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    2: function (oldDoc) {
      return { ...oldDoc, promotionId: oldDoc.promotionId || '' }
    },
    3: function (oldDoc) {
      if (oldDoc?.shoppingCartPrices) {
        delete oldDoc.shoppingCartPrices
      }
      return oldDoc
    },
    4: function (oldDoc) {
      if (oldDoc?.offerId && oldDoc?.items) {
        ;(oldDoc.items as { [x: string]: unknown }[]).forEach((item) => {
          item.offerItemPosition = item.offerItemPosition ?? -1
        })
      }
      return oldDoc
    },
    5: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
    6: function (oldDoc) {
      return { ...oldDoc, paymentMethod: oldDoc.paymehtMethod || '' }
    },
    7: function (oldDoc) {
      return { ...oldDoc, isUpdating: oldDoc.isUpdating || false }
    },
  },
  schema: {
    title: 'cartsv2',
    version: 7,
    description: 'a document representing a cart in the new shop',
    type: 'object',
    primaryKey: 'id',
    properties: {
      id: {
        type: 'string',
        maxLength: 100,
      },
      cartType: {
        type: 'string',
      },
      projectId: {
        type: 'string',
      },
      name: {
        type: 'string',
      },
      receiverId: {
        type: 'string',
      },
      commission: {
        type: 'string',
      },
      clerk: {
        type: 'string',
      },
      offerId: {
        type: 'string',
      },
      remark: {
        type: 'string',
      },
      phone: {
        type: 'string',
      },
      email: {
        type: 'string',
      },
      collector: {
        type: 'string',
      },
      items: {
        type: 'array',
      },
      shippingData: {
        type: 'object',
      },
      promotionSettings: {
        type: 'object',
      },
      messages: {
        type: 'array',
      },
      updatedAt: {
        type: 'string',
      },
      promotionId: {
        type: 'string',
      },
      paymentMethod: {
        type: 'string',
      },
      isUpdating: {
        type: 'boolean',
      },
    },
  },
  subscription: null,
  pullSync: {
    batchSize: 50,
    queryBuilder: (checkpoint, limit) => {
      checkpoint = checkpoint || emptyCheckpoint
      const query = print(GET_SHOPPING_CARTS)

      return {
        query,
        variables: {
          ...checkpoint,
          batchSize: limit,
        },
      }
    },
    responseModifier: (
      documents: (ShoppingCart & { _deleted: boolean })[],
      origin,
      requestCheckpoint = emptyCheckpoint
    ) => ({
      checkpoint:
        documents.length === 0
          ? requestCheckpoint
          : createCheckpoint(documents[documents.length - 1]),
      documents: documents,
    }),
  },
  authentication: true,
  liveInterval: 5 * 60 * 1000, // 5 min
}
