import { createContext, useContext, useState } from 'react'
import { ITeaser } from '@obeta/models/lib/models/Teasers/Teasers'
import { CategoryTree } from '@obeta/models/lib/models/Category'
import { INewsCard } from '@obeta/models/lib/models/News/News'
import {
  RecommendationProduct,
  PersonalRecommendationGrouping,
} from '@obeta/models/lib/models/Product/RecommendationProduct'
import { OrderForListPage } from '@obeta/models/lib/schema-models/order-list'

type StartPageDataValue = {
  teasersBlock1: ITeaser[]
  setTeasersBlock1: (teasers: ITeaser[]) => void
  teasersBlock2: ITeaser[]
  setTeasersBlock2: (teasers: ITeaser[]) => void
  teasersBlock3: ITeaser[]
  setTeasersBlock3: (teasers: ITeaser[]) => void
  teasersAll: ITeaser[]
  setTeasersAll: (teasers: ITeaser[]) => void
  categoryTree: CategoryTree[]
  setCategoryTree: (categoryTree: CategoryTree[]) => void
  news: INewsCard[]
  setNews: (news: INewsCard[]) => void
  timeLimit: number | null
  setTimeLimit: (limit: number) => void
  recommendations: {
    recommendations: RecommendationProduct[]
    recommendationsGroupings: PersonalRecommendationGrouping[]
  }
  setRecommendations: (recommendations: {
    recommendations: RecommendationProduct[]
    recommendationsGroupings: PersonalRecommendationGrouping[]
  }) => void
  orders: OrderForListPage[]
  setOrders: (orders: OrderForListPage[]) => void
}

const initialValue: StartPageDataValue = {
  teasersBlock1: [],
  setTeasersBlock1: () => {
    //
  },
  teasersBlock2: [],
  setTeasersBlock2: () => {
    //
  },
  teasersBlock3: [],
  setTeasersBlock3: () => {
    //
  },
  teasersAll: [],
  setTeasersAll: () => {
    //
  },
  categoryTree: [],
  setCategoryTree: () => {
    //
  },
  news: [],
  setNews: () => {
    //
  },
  timeLimit: null,
  setTimeLimit: () => {
    //
  },
  recommendations: {
    recommendations: [],
    recommendationsGroupings: [],
  },
  setRecommendations: () => {
    //
  },
  orders: [],
  setOrders: () => {
    //
  },
}

const StartPageDataContext = createContext<StartPageDataValue>(initialValue)

export const StartPageDataProvider = ({ children }) => {
  const [teasersBlock1, setTeasersBlock1] = useState<ITeaser[]>(initialValue.teasersBlock1)
  const [teasersBlock2, setTeasersBlock2] = useState<ITeaser[]>(initialValue.teasersBlock2)
  const [teasersBlock3, setTeasersBlock3] = useState<ITeaser[]>(initialValue.teasersBlock3)
  const [teasersAll, setTeasersAll] = useState<ITeaser[]>(initialValue.teasersAll)
  const [categoryTree, setCategoryTree] = useState<CategoryTree[]>([])
  const [news, setNews] = useState<INewsCard[]>([])
  const [timeLimit, setTimeLimit] = useState<number | null>(initialValue.timeLimit)
  const [recommendations, setRecommendations] = useState<StartPageDataValue['recommendations']>({
    recommendations: [],
    recommendationsGroupings: [],
  })
  const [orders, setOrders] = useState<OrderForListPage[]>([])

  return (
    <StartPageDataContext.Provider
      value={{
        teasersBlock1,
        teasersBlock2,
        teasersBlock3,
        teasersAll,
        setTeasersAll,
        setTeasersBlock1,
        setTeasersBlock2,
        setTeasersBlock3,
        categoryTree,
        setCategoryTree,
        news,
        setNews,
        timeLimit,
        setTimeLimit,
        recommendations,
        setRecommendations,
        orders,
        setOrders,
      }}
    >
      {children}
    </StartPageDataContext.Provider>
  )
}

export const useStartPageData = () => {
  return useContext(StartPageDataContext)
}
