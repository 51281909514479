import { createContext, useCallback, useContext, useEffect, useRef } from 'react'
import { CellMeasurerCache } from 'react-virtualized'

type ScopedContextType<T> = {
  currentKey?: string //set by keyFn
  lastScrollY?: number
  cellMeasureCache: CellMeasurerCache
  extra?: T
  setExtra: <T>(data: T) => void
}

const ScopedScrollRestorationContext = createContext<
  Record<string, Partial<ScopedContextType<unknown>>>
>({})

export const ScopedScrollRestorationProvider = function <T>({ children }) {
  const scopeStore = useRef<Record<string, ScopedContextType<T>>>({})
  return (
    <ScopedScrollRestorationContext.Provider value={scopeStore.current}>
      {children}
    </ScopedScrollRestorationContext.Provider>
  )
}

type UseScopedScrollRestorationParams<T> = {
  scopeName: string //identifies which part of the app the stored scroll params belong to
  keyFn?: () => string //if the key changes it automatically invalidates the current cache and starts from scratch, usefull for restetting when e.g. article ids change
  defaults?: Partial<ScopedContextType<T>> //provide an inital set of data to work with
}

export const useScopedScrollRestorationContext = function <T>(
  params: UseScopedScrollRestorationParams<T>
): ScopedContextType<T> {
  const allScopes = useContext(ScopedScrollRestorationContext)

  const { scopeName, defaults, keyFn = () => 'default' } = params
  //init scope
  if (!allScopes[scopeName] || allScopes[scopeName].currentKey !== keyFn()) {
    allScopes[scopeName] = { currentKey: keyFn() } as ScopedContextType<T>
  }
  const scope = allScopes[scopeName]

  if (scope.lastScrollY === undefined && defaults?.lastScrollY !== undefined) {
    scope.lastScrollY = defaults.lastScrollY
  }
  if (scope.cellMeasureCache === undefined && defaults?.cellMeasureCache !== undefined) {
    scope.cellMeasureCache = defaults.cellMeasureCache
  }

  const setExtra = useCallback(
    (data: unknown) => {
      scope.extra = data
    },
    [scope]
  )

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        scope.lastScrollY = window.scrollY
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scope])

  if (!scope.cellMeasureCache) {
    //we always supply one for ease of use in conjuction with other hooks
    scope.cellMeasureCache = new CellMeasurerCache()
  }
  scope.setExtra = setExtra

  return scope as ScopedContextType<T>
}
