import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'

interface CustomerAction {
  type: CustomerActionTypes
}

export enum CustomerActionTypes {
  UpdateCard = '[Customer] Update Customer Card',
  UpdateCardResult = '[Customer] Update Customer Card Result',
  DeleteUserToken = '[User] Delete User Push Token',
  LoadUserGraphql = '[User]] Load GraphQl User',
  LoadUserGraphqlResult = '[User]] Load GraphQl User Result',
  UpdateUserSettings = '[User] Update User Settings',
}

export interface UpdateCardAction extends CustomerAction {
  readonly type: CustomerActionTypes.UpdateCard
  cardId: string
}

export const updateCard = (cardId: string): UpdateCardAction => ({
  type: CustomerActionTypes.UpdateCard,
  cardId,
})

export interface UpdateCardResultAction extends CustomerAction {
  readonly type: CustomerActionTypes.UpdateCardResult
  error?: Error
}

export const updateCardResult = (error?: Error): UpdateCardResultAction => ({
  type: CustomerActionTypes.UpdateCardResult,
  error,
})

export interface LoadUserGraphQLAction extends CustomerAction {
  readonly type: CustomerActionTypes.LoadUserGraphql
}

export const loadUserGraphQl = (): LoadUserGraphQLAction => ({
  type: CustomerActionTypes.LoadUserGraphql,
})

export interface LoadUserGraphQlResultAction extends CustomerAction {
  readonly type: CustomerActionTypes.LoadUserGraphqlResult
  user?: UserV2
  error?: Error
}

export const loadUserGraphQLResult = (
  user?: UserV2,
  error?: Error
): LoadUserGraphQlResultAction => ({
  type: CustomerActionTypes.LoadUserGraphqlResult,
  user,
  error,
})

export interface UpdateUserSettingsAction {
  readonly type: CustomerActionTypes.UpdateUserSettings
  payload: {
    defaultStoreId?: string
    defaultCartId?: string
  }
}

export const updateUserSettings = (
  payload: UpdateUserSettingsAction['payload']
): UpdateUserSettingsAction => ({
  type: CustomerActionTypes.UpdateUserSettings,
  payload,
})

export interface DeleteUserToken extends CustomerAction {
  readonly type: CustomerActionTypes.DeleteUserToken
}
export const deleteUserToken = (): DeleteUserToken => ({
  type: CustomerActionTypes.DeleteUserToken,
})
