import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { OrdersFacetsV2 } from '@obeta/schema'
import { OrderForListPage } from '@obeta/models/lib/schema-models/order-list'
import { DEFAULT_ORDER_LIST_QUERY_PARAMS } from '@obeta/models/lib/models/Orders'
import { QueryParams } from '@obeta/models/lib/models/VirtualizedList'

const initialEmptyFunction = () => {
  //
}

interface OrderListContextValue {
  facets: OrdersFacetsV2 | null
  isLoading: boolean
  orders: OrderForListPage[]
  isLoadingPage: boolean
  queryParams: QueryParams
  totalOrderCount: number
  resetOrders: () => void
  setFacets: (facets: OrdersFacetsV2 | null) => void
  setIsLoading: (isLoading: boolean) => void
  setIsLoadingPage: (isLoadingPage: boolean) => void
  setOrders: (orders: OrderForListPage[]) => void
  setQueryParams: (queryParams: QueryParams) => void
  setTotalOrderCount: (totalOrderCount: number) => void
}

const OrderListContext = createContext<OrderListContextValue>({
  facets: null,
  isLoading: true,
  orders: [],
  isLoadingPage: true,
  queryParams: DEFAULT_ORDER_LIST_QUERY_PARAMS,
  totalOrderCount: 0,
  resetOrders: initialEmptyFunction,
  setFacets: initialEmptyFunction,
  setIsLoading: initialEmptyFunction,
  setIsLoadingPage: initialEmptyFunction,
  setOrders: initialEmptyFunction,
  setQueryParams: initialEmptyFunction,
  setTotalOrderCount: initialEmptyFunction,
})

export const OrderListProvider = ({ children }) => {
  const [facets, setFacets] = useState<OrdersFacetsV2 | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [orders, setOrders] = useState<OrderForListPage[]>([])
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true)
  const [queryParams, setQueryParams] = useState<QueryParams>(DEFAULT_ORDER_LIST_QUERY_PARAMS)
  const [totalOrderCount, setTotalOrderCount] = useState<number>(0)

  /**
   * Reset order list state on nav section menu button click.
   */
  const resetOrders = useCallback(() => {
    setOrders([])
    setTotalOrderCount(0)
    setQueryParams(DEFAULT_ORDER_LIST_QUERY_PARAMS)
  }, [])

  const value: OrderListContextValue = useMemo(
    () => ({
      facets,
      isLoading,
      isLoadingPage,
      orders,
      queryParams,
      totalOrderCount,
      resetOrders,
      setFacets,
      setIsLoading,
      setIsLoadingPage,
      setOrders,
      setQueryParams,
      setTotalOrderCount,
    }),
    [facets, isLoading, isLoadingPage, orders, queryParams, totalOrderCount, resetOrders]
  )

  return <OrderListContext.Provider value={value}>{children}</OrderListContext.Provider>
}

export const useOrderListContext = () => {
  return useContext(OrderListContext)
}
