import { useEntities } from './useEntities'
import { UserAddressType, UserAddressV2 } from '@obeta/models/lib/models/Users/UserV2'

export const useUserAddressesV2 = () => {
  const userAddresses = useEntities<UserAddressV2>('useraddressesv2')

  const mainAddress = userAddresses.find(
    (address) => address.addressType === UserAddressType.MainAddress
  )

  return { userAddresses, mainAddress }
}
