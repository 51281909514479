import React, { useState, useCallback } from 'react'
import { ArticleAddOptions } from '@obeta/models/lib/models/Article/ArticleListItem'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { CartSelectionMenu, CART_SELECTION_CONTEXT } from '../menus'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { MenuWrapper } from '../menus/MenuWrapper'
import { GenericToast } from './genericToast'
import { Typography, ButtonBase } from '@mui/material'
import './switchTargetCartToast.scss'

interface SwitchToastProps {
  event: ArticleAddOptions
  onDidDismiss?: () => void
  onChangeChoosen?: (changeToCartId: ShoppingCartV2) => void
  open?: boolean
}

export const SwitchTargetCartToast: React.FC<SwitchToastProps> = (props) => {
  const { onDidDismiss, open, onChangeChoosen, event } = props
  const { singleCartMode } = useUserDataV2()
  const [isOpen, setOpen] = useState(!!open)

  const [showCartSelectionMenu, setShowCartSelectionMenu] = useState(false)
  const closeCartSelectionMenu = useCallback(() => {
    setShowCartSelectionMenu(false)
  }, [])

  const maxLength = 30
  let name = event.articleName
  name = name.length > maxLength ? name.slice(0, maxLength) + '...' : name
  let cart = event.shoppingCartName
  cart = cart.length > maxLength ? cart.slice(0, maxLength - 3) + '...' : cart

  return (
    <>
      {showCartSelectionMenu && (
        <MenuWrapper show={showCartSelectionMenu} onMenuClosed={closeCartSelectionMenu}>
          <CartSelectionMenu
            context={CART_SELECTION_CONTEXT.MOVE_ARTICLE}
            onCloseClick={closeCartSelectionMenu}
            unselectableIds={[event.shoppingCartId]}
            onCartSelected={(cart) => {
              closeCartSelectionMenu()
              onChangeChoosen && onChangeChoosen(cart)
            }}
          ></CartSelectionMenu>
        </MenuWrapper>
      )}
      <GenericToast
        open={isOpen}
        autoHideDuration={6000}
        className={'switchTargetToast'}
        message={
          <div className="messageWrapper">
            <Typography className="text" variant="body">
              Artikel <b>'{name}'</b> dem Warenkorb <b>'{cart}'</b> hinzugefügt
            </Typography>
            {!singleCartMode && (
              <ButtonBase
                onClick={() => {
                  setOpen(false)
                  setShowCartSelectionMenu(true)
                }}
              >
                <Typography className="btnText" variant="bodyBold">
                  VERSCHIEBEN
                </Typography>
              </ButtonBase>
            )}
          </div>
        }
        onClose={onDidDismiss}
      />
    </>
  )
}
