import { useState, useEffect } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { useWarehouseContext } from '../stores/useWarehouseContext'
import { datadogRum } from '@datadog/browser-rum'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { calcProductTotalPrice } from '@obeta/utils/lib/productPrices'
import { ProductDetailPrices } from '@obeta/schema'
import { MaybeCompleteSearchProduct } from '@obeta/models/lib/schema-models'

type ArticleData = {
  articleId: string
  amount: number
  article?: MaybeCompleteSearchProduct
}

type ArticleDataWithPrice = ArticleData & {
  price: number
}

const getProductDetailPrices = gql`
  query GetProductDetailPrices($sapId: String!) {
    getProductDetailPrices(sapId: $sapId) {
      listPrice
      bulkPrice
      bulkPriceAmount
      priceDimension
      minimumAmount
      catalogPrice
      metalNeAddition
      netPrice
      tecSelect
      strikeThroughPrice
      currency
    }
  }
`

const getProductsQuery = gql`
  query searchProductsToImportIntoCart($sapIds: [String!]!, $warehouseIds: [String!]!) {
    getProducts(sapIds: $sapIds) {
      title
      articleDescription
      sapId
      imageData {
        sapId
        images {
          large
        }
      }
      oxomiId
      isDeleted
      isSendable
      isForSale
      isDiscontinued
      isTopseller
      priceDimension
      type
      unit
      minimumAmount
      dehaId
      obetaId
      isTopseller
      supplierImageData {
        large
      }
      images {
        width
        url
      }
      stock(warehouseIds: $warehouseIds) {
        amount
        unit
      }
      prices {
        listPrice
        bulkPrice1
        catalogPrice
        metalNeAddition
        netPrice
        tecSelect
        strikeThroughPrice
        currency
      }
      supplierImageData {
        large
      }
      images {
        width
        url
      }
      replacementProducts {
        title
        sapId
        articleDescription
        imageData {
          sapId
          images {
            large
          }
        }
      }
    }
  }
`

export const useArticleImport = (articleIdsRaw: string, amountsRaw: string) => {
  const client = useApolloClient()
  const [isLoading, setIsLoading] = useState(true)
  const [isPricesLoading, setIsPricesLoading] = useState(true)

  const [articleData, setArticleData] = useState<ArticleData[]>([])
  const [articlePricesData, setArticlePricesData] = useState<ArticleDataWithPrice[]>([])
  const { warehouseId } = useWarehouseContext()

  useEffect(() => {
    const fetchArticlesAndPrices = async () => {
      setIsLoading(true)

      if (!articleIdsRaw || !amountsRaw) {
        setIsLoading(false)
        return setArticleData([])
      }

      const articleIds = articleIdsRaw.split(',')
      const amounts = amountsRaw.split(',')

      datadogRum.addAction('cart import', {
        message: 'Start to search articles',
        data: { articleIds, amounts },
      })

      try {
        const response = await client.query<{ getProducts: MaybeCompleteSearchProduct[] }>({
          query: getProductsQuery,
          variables: { sapIds: articleIds, warehouseIds: [warehouseId] },
        })

        const articleItems = response.data.getProducts

        const amountsIdsMatch = articleIds.reduce((prev, cur, idx) => {
          return { ...prev, [cur]: amounts[idx] }
        }, {})

        let matches = 0
        const data = articleItems.map((article) => {
          const isTargetArticle = article.sapId === article.obetaId
          const articleId = article.sapId
          const amount = parseInt(amountsIdsMatch?.[article.sapId], 10)

          if (isTargetArticle) {
            matches++
            return { articleId, amount, article }
          }

          return { articleId, amount, article }
        }) as ArticleData[]

        datadogRum.addAction('cart import', {
          message: `Found ${matches} article with requested IDs`,
        })

        setIsLoading(false)
        setArticleData(data)
      } catch (error) {
        setIsLoading(false)
        handleError(error)
      }
    }

    fetchArticlesAndPrices()
  }, [articleIdsRaw, amountsRaw, warehouseId, client])

  useEffect(() => {
    // TODO: Create an alternative for getProductDetailPrices that accepts multiple IDs to perform the request in a single iteration.
    // Making a separate request for each article here doesn't make sense.
    // Since having many articles might slow down the price display on the button, it will be necessary to split the requests into batches.
    const fetchPricesAndMathWithArticles = async (data: ArticleData[]) => {
      if (isLoading) return
      if (!articleData.length) return setIsPricesLoading(false)

      setIsPricesLoading(true)

      try {
        const articleIdsForPrices = data.map((article) => article.articleId)
        const pricePromises = articleIdsForPrices.map((articleId) =>
          client.query<{ getProductDetailPrices: ProductDetailPrices }>({
            query: getProductDetailPrices,
            variables: { sapId: articleId },
          })
        )

        const priceResponses = await Promise.all(pricePromises)

        const priceResults = priceResponses.map((response, index) => ({
          articleId: articleIdsForPrices[index],
          priceData: response.data.getProductDetailPrices,
        }))

        const updatedArticleData = data.map((article, index) => {
          const price = priceResults[index]?.priceData
            ? calcProductTotalPrice({
                ...priceResults[index]?.priceData,
                amount: priceResults[index].priceData.minimumAmount,
              }).total
            : 0

          return { ...article, price: price }
        })

        setArticlePricesData(updatedArticleData)
        setIsPricesLoading(false)
      } catch (err) {
        setIsPricesLoading(false)
      }
    }

    fetchPricesAndMathWithArticles(articleData)
  }, [articleData, isLoading, client])

  return { articleData, isLoading, isPricesLoading, articlePricesData }
}
