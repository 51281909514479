/**
 * We need symbol observable polyfill to make all "rxjs" versions compatible.
 * TODO: Looks like we have several "rxjs" packages included in bundle.
 * One of the "rxjs" is imported before 'symbol-ovservable'.
 * And other after. Because of this they stop being comatible.
 * ('symbol-observable' is used to validate observables)
 * the fact that we have several "rxjs" in bundle is BUG.
 * We need to analyze bundle and find soltion
 */
import 'symbol-observable'
import React, { useEffect, useMemo, useRef } from 'react'
import { Provider, useDispatch } from 'react-redux'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { CacheProvider } from '@emotion/react'
import { ApolloProvider } from '@apollo/client'
import { Provider as RxDBProvider } from 'rxdb-hooks'

// Actions
import { loadUserGraphQl } from '@obeta/data/lib/actions/customer-actions'
import 'assets/theme/variables.scss'
// Components
import { AppWrapper } from '../components/AppWrapper'
import { DeployRefreshManager } from '@obeta/data/lib/components/DeployRefreshManager'
import { ErrorBoundary } from '@obeta/components/lib/error-boundary/ErrorBoundary'
import { Footer } from '@obeta/components/lib/footer/Footer'
import { GlobalNotifications } from '@obeta/components/lib/global-notifications/GlobalNotifications'
import { MainWrapper } from '../components/MainWrapper/MainWrapper'
import { OperationsIntegration } from '@obeta/data/lib/components/operations-integration/OperationsIntegration'
import { PageElementsProvider } from '@obeta/components/lib/page-elements/PageElements'
import { ShopHeader } from '@obeta/components/lib/header'
import { Sockets } from '@obeta/components/lib/sockets/Sockets'
import { Maintenance } from '@obeta/components/lib/maintenance/Maintenance'

// Hooks
import { AppActionsContext } from '@obeta/data/lib/hooks/useAppActions'
import { ArticlesSearchProvider } from '@obeta/data/lib/hooks/useArticleSearchProvider'
import { useAppDomain } from '@obeta/data/lib/hooks/useAppDomain'
import { CategoryContext, useCategoriesState } from '@obeta/data/lib/hooks/useCategoriesList'
import { DbUtilsContext } from '@obeta/data/lib/hooks/useDbUtils'
import { HeaderDimensionsContextProvider } from '@obeta/data/lib/hooks/useHeaderDimensions'
import { NextJSRouterProvider, useLocation } from '@obeta/data/lib/hooks/useHistoryApi'
import { useLoginListeners } from '@obeta/data/lib/hooks/useLogin'
import { useLogoutCleanup } from '@obeta/data/lib/hooks/useLogoutCleanup'
import { useLogoutRedirect } from '@obeta/data/lib/hooks/useLogoutRedirect'
import { useShopBootstrap } from '@obeta/app-bootstrap/lib/hooks/useShopBootstrap'
import { useShopInfo } from '@obeta/data/lib/hooks/useShopInfo'
import {
  useInitialUserDataV2,
  UserV2DataContext,
  useUserDataV2,
} from '@obeta/data/lib/hooks/useUserDataV2'
import { SelectionBarContainer } from '@obeta/components/lib/selection-bar/SelectionBarContainer'

// Providers
import { CompanyContactDetailsProvider } from '@obeta/data/lib/stores/useCompanyContactDetailsContext'
import { MuiThemeProvider } from '@obeta/components/lib/mui-theme-provider-5/MuiThemeProvider'

// Styles
import './styles.scss'

// Utils
import { BusinessLayerContext, cancelReplication } from '@obeta/app-bootstrap'
import { createEmotionCache } from '@obeta/utils/lib/createEmotionCache'
import { ShopDomains } from '@obeta/utils/lib/domains'
import { GoggleMapsContextProvider } from '@obeta/data/lib/hooks/googleMaps/GoogleMapsContextProvider'
import { loadI18n } from '@obeta/utils/lib/i18n'
import { interFrameCommunication } from '@obeta/utils/lib/interFrameCommunication'
import { PAGES_HEADERLESS, isPageMatch, PAGES_SIGNUP } from '@obeta/utils/lib/styles-helpers'
import { SessionMetaProvider } from '@obeta/data/lib/hooks/useSessionMeta'
import { PageMarginWrapper } from '@obeta/components/lib/page-margin-wrapper/PageMarginWrapper'
import { LicenseInfo } from '@mui/x-license-pro'
import CssBaseline from '@mui/material/CssBaseline'
import { OfferDetailsContextProvider } from '@obeta/data/lib/stores/useOfferDetailsContext'
import { OrderListProvider } from '@obeta/data/lib/stores/useOrderListContext'
import { StartPageDataProvider } from '@obeta/data/lib/hooks/useStartPageDataProvider'
import { PageBackgroundWrapper } from '@obeta/components/lib/page-background-wrapper/PageBackgroundWrapper'
import { WarehouseProvider } from '@obeta/data/lib/stores/useWarehouseContext'
import { SessionContextDebugProvider } from '@obeta/data/lib/stores/useSessionContextDebugContext'
import { FastProductEntryProvider } from '@obeta/data/lib/stores/useFastProductEntryContext'
import { OrderDetailsContextProvider } from '@obeta/data/lib/stores/useOrderDetailsContext'
import { ScopedScrollRestorationProvider } from '@obeta/data/lib/hooks'
import { OrderDetailsInArrearsContextProvider } from '@obeta/data/lib/stores/useOrderDetailsInArrearsContext'
import { CartTemplatesListContextProvider } from '@obeta/data/lib/stores/useCartTemplatesListContext'
import { CreditLimitPreAlertProvider } from '@obeta/data/lib/stores/useCreditLimitPreAlertContext'
import { getInitialPropsAppCallback } from '../utils/getInitialPropsAppCallback'
import { useOxomiIntegration } from '@obeta/data/lib/hooks/useOxomiIntegration'
import { useOxomiProductEnhancement } from '@obeta/data/lib/hooks/useOxomiProductEnhancement'
import { useObetaResizeObserver } from '@obeta/data/lib/hooks/useObetaResizeObserver'
import { SignUpShopHeader } from '@obeta/components/lib/header/SignUpHeader'
import SignUpFooter from '@obeta/layouts/lib/startPage/SignUpFooter'

const cache = createEmotionCache()
const graphqlUrl = process.env.NEXT_PUBLIC_GRAPHQL_HOST
LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY ?? '')

/**
 * root component renders "app" by condition.
 * because of this app should be isolated into its own component.
 * contentElRef.current won't be avaialable on componentDidMount. this will break
 * ResizeObserver.
 * @param props
 * @returns
 */
const App: React.FC = (props) => {
  const { children } = props
  const router = useRouter()
  const contentElRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)

  initHookUrlFromRoute(router)

  const SESSIONCATALOGPAGEPATH = '/sessionCatalogStartPage'

  const catalogUserSessionId =
    router.pathname === SESSIONCATALOGPAGEPATH ? (router.query.sessionId as string) : ''

  useEffect(() => {
    interFrameCommunication.init()
  }, [])

  useObetaResizeObserver(contentElRef, () => {
    interFrameCommunication.setIFrameHeight()
  })

  const location = useLocation()
  const userV2Data = useInitialUserDataV2()

  useLogoutRedirect('/?logout=1')

  useLogoutCleanup(userV2Data.isLoggedIn)

  const domain = useAppDomain()
  const { ShopName } = useShopInfo()

  useEffect(() => {
    if (!userV2Data.isLoggedIn) {
      cancelReplication()
    }
  }, [userV2Data.isLoggedIn])

  let cookieBotIdentifier
  if (domain === ShopDomains.Eldis) {
    cookieBotIdentifier = '9ca5647b-51ab-42be-8b5e-71b39edef1ce'
  } else if (domain === ShopDomains.Haeusler) {
    cookieBotIdentifier = '8af8d382-ea6a-4cea-9386-7ad3ac7170b0'
  } else {
    cookieBotIdentifier = '6088c194-9674-40d5-af7e-7fcd890b9521'
  }

  // oxomi
  useOxomiIntegration()
  useOxomiProductEnhancement()

  return useMemo(() => {
    return (
      <AppWrapper isLoggedIn={userV2Data.isLoggedIn}>
        <Head>
          {window?.location?.origin !== 'http://localhost:4200' && (
            <script
              id="Cookiebot"
              src="https://consent.cookiebot.com/uc.js"
              data-cbid={cookieBotIdentifier}
              type="text/javascript"
            ></script>
          )}
          <title>{ShopName}</title>
        </Head>
        <CacheProvider value={cache}>
          <MuiThemeProvider>
            <CssBaseline />
            <StartPageDataProvider>
              <UserV2DataContext.Provider value={userV2Data}>
                <WarehouseProvider>
                  <CompanyContactDetailsProvider>
                    <ArticlesSearchProvider>
                      <OrderListProvider>
                        <OrderDetailsContextProvider>
                          <OfferDetailsContextProvider>
                            <OrderDetailsInArrearsContextProvider>
                              <CartTemplatesListContextProvider>
                                <FastProductEntryProvider>
                                  <OperationsIntegration>
                                    <HeaderDimensionsContextProvider>
                                      <CreditLimitPreAlertProvider>
                                        <PageBackgroundWrapper path={router.asPath} className="app">
                                          <LoadUser />
                                          <Sockets
                                            userId={userV2Data.userId}
                                            companyId={userV2Data.companyId}
                                          >
                                            <GlobalNotifications />
                                            {!isPageMatch(router.asPath, PAGES_HEADERLESS) &&
                                              !isPageMatch(router.asPath, PAGES_SIGNUP) && (
                                                <>
                                                  <ShopHeader ref={headerRef} />
                                                  <LoginHeader
                                                    catalogUserSessionId={catalogUserSessionId}
                                                  />
                                                </>
                                              )}
                                            {isPageMatch(router.asPath, PAGES_SIGNUP) && (
                                              <SignUpShopHeader ref={headerRef} />
                                            )}
                                            <MainWrapper>
                                              {/* Unset top margin on start page */}
                                              <div
                                                ref={contentElRef}
                                                onLoad={interFrameCommunication.setIFrameHeight}
                                              >
                                                <ScopedScrollRestorationProvider>
                                                  {' '}
                                                  <PageMarginWrapper pageRoute={location.pathname}>
                                                    <PageElementsProvider
                                                      pageRoute={location.pathname}
                                                      headerElementRef={headerRef}
                                                    >
                                                      {children}
                                                    </PageElementsProvider>
                                                  </PageMarginWrapper>
                                                </ScopedScrollRestorationProvider>
                                              </div>
                                            </MainWrapper>
                                            <SelectionBarContainer />
                                            {!isPageMatch(router.asPath, PAGES_SIGNUP) && (
                                              <Footer />
                                            )}
                                            {isPageMatch(router.asPath, PAGES_SIGNUP) && (
                                              <SignUpFooter />
                                            )}
                                          </Sockets>
                                        </PageBackgroundWrapper>
                                      </CreditLimitPreAlertProvider>
                                    </HeaderDimensionsContextProvider>
                                  </OperationsIntegration>
                                </FastProductEntryProvider>
                              </CartTemplatesListContextProvider>
                            </OrderDetailsInArrearsContextProvider>
                          </OfferDetailsContextProvider>
                        </OrderDetailsContextProvider>
                      </OrderListProvider>
                    </ArticlesSearchProvider>
                  </CompanyContactDetailsProvider>
                </WarehouseProvider>
              </UserV2DataContext.Provider>
            </StartPageDataProvider>
          </MuiThemeProvider>
        </CacheProvider>
      </AppWrapper>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, location.pathname, userV2Data, catalogUserSessionId])
}

loadI18n().then()

const LoadUser: React.FC = (props) => {
  const dispatch = useDispatch()
  const { isLoggedIn } = useUserDataV2()

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }

    dispatch(loadUserGraphQl())
  }, [isLoggedIn, dispatch])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}

const initHookUrlFromRoute = (router) => {
  const isFrontend = typeof window !== 'undefined'
  if (!isFrontend) {
    return ''
  }
  const matches = router.asPath.match(/&hookUrl=(.*)/)
  const hookUrl = matches ? matches[1] : ''
  if (hookUrl && hookUrl.length > 0) {
    localStorage.setItem('hookUrl', hookUrl)
  }
}

const Memoized: React.FC<Pick<AppProps, 'Component' | 'pageProps'>> = (props) => {
  const { Component, pageProps } = props
  const allRefs = useCategoriesState()
  const { db, dbUtils, store, appActions, apolloClient, businessLayer } = useShopBootstrap(
    true,
    graphqlUrl,
    true
  )

  if (!db || !dbUtils || !store || !apolloClient) {
    return null
  }

  const isDeployRefreshManagerEnabled = window?.location?.origin !== 'http://localhost:4200'

  return (
    <NextJSRouterProvider>
      <ApolloProvider client={apolloClient}>
        <BusinessLayerContext.Provider value={businessLayer}>
          <RxDBProvider db={db}>
            <DbUtilsContext.Provider value={dbUtils}>
              <AppActionsContext.Provider value={appActions}>
                <GoggleMapsContextProvider>
                  <CategoryContext.Provider value={allRefs}>
                    <Provider store={store}>
                      <SessionMetaProvider>
                        <SessionContextDebugProvider>
                          <ErrorBoundary>
                            {
                              <Maintenance>
                                <App>
                                  {isDeployRefreshManagerEnabled && <DeployRefreshManager />}
                                  <Component {...pageProps} />
                                </App>
                              </Maintenance>
                            }
                          </ErrorBoundary>
                        </SessionContextDebugProvider>
                      </SessionMetaProvider>
                    </Provider>
                  </CategoryContext.Provider>
                </GoggleMapsContextProvider>
              </AppActionsContext.Provider>
            </DbUtilsContext.Provider>
          </RxDBProvider>
        </BusinessLayerContext.Provider>
      </ApolloProvider>
    </NextJSRouterProvider>
  )
}

export type MetaInfo = { property: string; content: string } | { name: string; content: string }

type CustomAppProps = AppProps & {
  metaInfo?: MetaInfo[]
  structuredData?: Record<string, string>
}

const CustomApp = (props: CustomAppProps) => {
  const { Component, pageProps, metaInfo, structuredData } = props

  const {
    locale,
    pathname,
    query: { slug },
  } = useRouter()

  // https://github.com/vercel/next.js/issues/34729#issuecomment-1079073617
  const app = useMemo(() => {
    return <Memoized Component={Component} pageProps={pageProps} />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, slug, pathname, metaInfo])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link
          rel="sitemap"
          type="application/xml"
          title="Sitemap"
          href="https://obeta.de/sitemap-index.xml"
        />
        {metaInfo?.map((meta, idx) => {
          return (
            <meta
              key={idx}
              {...('property' in meta ? { property: meta.property } : {})}
              {...('name' in meta ? { name: meta.name } : {})}
              content={meta.content}
            />
          )
        })}
        {structuredData && (
          <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        )}
      </Head>
      {app}
    </>
  )
}

CustomApp.getInitialProps = getInitialPropsAppCallback

export default CustomApp

const LoginHeader: React.FC<{ catalogUserSessionId?: string }> = (props) => {
  const { catalogUserSessionId } = props
  useLoginListeners(catalogUserSessionId)

  return null
}
